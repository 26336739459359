@keyframes fade {
  from {
    opacity: 1.0;
  }

  50% {
    opacity: 0.5;
  }

  to {
    opacity: 1.0;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1.0;
  }

  50% {
    opacity: 0.5;
  }

  to {
    opacity: 1.0;
  }
}

.card0 {
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
}

.number0 {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: none;
  color: blueviolet;
  font-family: 'Lemon', serif;
  font-weight: 700;
  text-shadow: 1px 1px 0 #f4eeee;;

}

.box-animation {
  position: relative;
  animation: fade 1000ms infinite;
  -webkit-animation: fade 1000ms infinite;
  border: 7px solid rgb(204, 22, 143) !important;
  box-shadow: -7px 10px 25px 0 rgb(204, 22, 143) !important;
  border-radius: 10px;
  left: 0.5%;
  bottom: 0.5%;
}