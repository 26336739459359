.card1 {
    border: 2px solid blueviolet !important;
    box-shadow: 2px 1px 2px 1px #858485 !important;
    height: 42px;
    width: 42px;
    border-radius: 10px !important;
    align-items: center !important;
    background-color: #f4eeee !important;
}

.number {
    height: 42px;
    width: 42px;
    text-align: center;
    background-color: none;
    color: blueviolet;
    font-family: 'Lemon', serif;
    font-weight: 700;
    text-shadow: 1px 1px 0 #f4eeee;
    padding-top: 7px;
    padding-right: 4px;
 
}

.card-animation {
    animation: fade 1000ms infinite;
    -webkit-animation: fade 1000ms infinite;
    border: 3px solid rgb(204, 22, 143)!important;
    border-radius: 30%;
}
