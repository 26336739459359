
.board{
  height: 520px;
  width: 520px;
  margin-top: 10px;
  box-shadow: 5px 5px 10px 7px #676565;
  border-radius: 10px;
}

.player1{
  height: 250px;
  width: 200px;
}
.player2{
  height: 250px;
  width: 220px;
}
.asset1{
  height: 250px;
  width: 200px;
}
.play-button {
  height: 150px;
  width: 350px;
  border-radius: 100px;
}

.player1-play-button{
   margin-bottom: 300px;
   margin-left: -110px;
}

.player2-play-button{
  margin-right: 0px;

}

.player2-div{
  margin-top: 100px;

}

.player1-div{
  margin-top: 60px;

}




@keyframes fade {
  from { opacity: 1.0; height: 150px;}
  50% { opacity: 0.5; height: 100px ;}
  to { opacity: 1.0; height: 150px;}
}                                                                                                                                                                                                                                  

@-webkit-keyframes fade {
  from { opacity: 1.0; height: 150px;}
  50% { opacity: 0.5; height: 100px ;}
  to { opacity: 1.0; height: 150px;}
}

.animation {
animation:fade 1000ms infinite;
-webkit-animation:fade 1000ms infinite;
}





















@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
