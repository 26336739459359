.keyboard {
    margin-top: 10px;
    border: 2px solid rgb(249, 247, 247) !important;
}

/* @media screen and (min-width: 48em) {
    .keyboard {
        margin-top: -10px;
    }
    
  } */